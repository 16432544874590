export var detalleCarreras = [
  {
    id_carrera: '40',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Comercial',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,85',
        ranking_demanda: '1',
        ranking_competitividad: '121',
        texto_nivel_competividad: 'Muy alta',
        texto_nivel_demanda: 'Muy Alta',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy alta, está en el lugar 121 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy alta, está en el lugar 1 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda muy alta y competencia muy alta. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_1',
        color_tramo_competitividad: 'ctc_5',
      },
    ],
  },
  {
    id_carrera: '354',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Civil Industrial',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '0,99',
        ranking_demanda: '2',
        ranking_competitividad: '120',
        texto_nivel_competividad: 'Muy alta',
        texto_nivel_demanda: 'Muy Alta',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy alta, está en el lugar 120 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy alta, está en el lugar 2 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy alta y competencia muy alta. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_1',
        color_tramo_competitividad: 'ctc_5',
      },
    ],
  },
  {
    id_carrera: '39',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Civil',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,03',
        ranking_demanda: '3',
        ranking_competitividad: '119',
        texto_nivel_competividad: 'Muy alta',
        texto_nivel_demanda: 'Muy Alta',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy alta, está en el lugar 119 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy alta, está en el lugar 3 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy alta y competencia muy alta. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_1',
        color_tramo_competitividad: 'ctc_5',
      },
    ],
  },
  {
    id_carrera: '439',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Mecánica Automotriz',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,21',
        ranking_demanda: '4',
        ranking_competitividad: '64',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Muy Alta',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 64 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy alta, está en el lugar 4 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera técnico profesional con demanda muy alta y competencia baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_1',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '2',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Administración de Empresas',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,76',
        ranking_demanda: '5',
        ranking_competitividad: '115',
        texto_nivel_competividad: 'Alta',
        texto_nivel_demanda: 'Muy Alta',
        descripcion_competencia:
          'Esta carrera tiene una competencia alta, está en el lugar 115 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy alta, está en el lugar 5 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda muy alta y competencia alta. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_1',
        color_tramo_competitividad: 'ctc_4',
      },
    ],
  },
  {
    id_carrera: '23',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Contador Auditor',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,76',
        ranking_demanda: '6',
        ranking_competitividad: '114',
        texto_nivel_competividad: 'Alta',
        texto_nivel_demanda: 'Alta',
        descripcion_competencia:
          'Esta carrera tiene una competencia alta, está en el lugar 114 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda alta, está en el lugar 6 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda alta y competencia alta. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_2',
        color_tramo_competitividad: 'ctc_4',
      },
    ],
  },
  {
    id_carrera: '473',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Administración de Empresas',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,06',
        ranking_demanda: '7',
        ranking_competitividad: '118',
        texto_nivel_competividad: 'Muy alta',
        texto_nivel_demanda: 'Alta',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy alta, está en el lugar 118 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda alta, está en el lugar 7 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda alta y competencia muy alta. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_2',
        color_tramo_competitividad: 'ctc_5',
      },
    ],
  },
  {
    id_carrera: '334',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Civil en Informática',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,5',
        ranking_demanda: '8',
        ranking_competitividad: '100',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Alta',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 100 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda alta, está en el lugar 8 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda alta y competencia promedio. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_2',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '231',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Prevención de Riesgos',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,41',
        ranking_demanda: '9',
        ranking_competitividad: '88',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Alta',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 88 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda alta, está en el lugar 9 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera profesional con demanda alta y competencia baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_2',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '104',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Computación e Informática',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,38',
        ranking_demanda: '10',
        ranking_competitividad: '81',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Alta',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 81 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda alta, está en el lugar 10 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera profesional con demanda alta y competencia baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_2',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '238',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico en Enfermería',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,47',
        ranking_demanda: '11',
        ranking_competitividad: '90',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Alta',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 90 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda alta, está en el lugar 11 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera técnico profesional con demanda alta y competencia baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_2',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '81',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Gastronomía y Cocina Internacional',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,1',
        ranking_demanda: '12',
        ranking_competitividad: '14',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Alta',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 14 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda alta, está en el lugar 12 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera profesional con demanda alta y competencia muy baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_2',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '273',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Electromecánica',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,42',
        ranking_demanda: '13',
        ranking_competitividad: '71',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Alta',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 71 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda alta, está en el lugar 13 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera técnico profesional con demanda alta y competencia baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_2',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '450',
    detalle_carrera: [
      {
        texto_nombre_carrera:
          'Técnico de Nivel Superior en Administración de Empresas',
        texto_nombre_tipo_carrera: 'Sin Definir',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,68',
        ranking_demanda: '14',
        ranking_competitividad: '117',
        texto_nivel_competividad: 'Alta',
        texto_nivel_demanda: 'Alta',
        descripcion_competencia:
          'Esta carrera tiene una competencia alta, está en el lugar 117 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda alta, está en el lugar 14 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera sin definir con demanda alta y competencia alta. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_2',
        color_tramo_competitividad: 'ctc_4',
      },
    ],
  },
  {
    id_carrera: '192',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Logística',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,77',
        ranking_demanda: '15',
        ranking_competitividad: '103',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Alta',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 103 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda alta, está en el lugar 15 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda alta y competencia promedio. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_2',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '122',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Civil Eléctrica',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,77',
        ranking_demanda: '16',
        ranking_competitividad: '104',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Promedio',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 104 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda promedio, está en el lugar 16 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda promedio y competencia promedio. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_3',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '22',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Construcción Civil',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,9',
        ranking_demanda: '17',
        ranking_competitividad: '107',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Promedio',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 107 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda promedio, está en el lugar 17 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda promedio y competencia promedio. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_3',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '342',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Mantenimiento Industrial',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,39',
        ranking_demanda: '18',
        ranking_competitividad: '62',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Promedio',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 62 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda promedio, está en el lugar 18 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera técnico profesional con demanda promedio y competencia baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_3',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '193',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Mantenimiento Industrial',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,39',
        ranking_demanda: '19',
        ranking_competitividad: '59',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Promedio',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 59 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda promedio, está en el lugar 19 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera profesional con demanda promedio y competencia muy baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_3',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '99',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Contabilidad General',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,2',
        ranking_demanda: '20',
        ranking_competitividad: '110',
        texto_nivel_competividad: 'Alta',
        texto_nivel_demanda: 'Promedio',
        descripcion_competencia:
          'Esta carrera tiene una competencia alta, está en el lugar 110 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda promedio, está en el lugar 20 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda promedio y competencia alta. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_3',
        color_tramo_competitividad: 'ctc_4',
      },
    ],
  },
  {
    id_carrera: '511',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Administración Gastronómica',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,11',
        ranking_demanda: '21',
        ranking_competitividad: '11',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Promedio',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 11 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda promedio, está en el lugar 21 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera profesional con demanda promedio y competencia muy baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_3',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '204',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico en Electricidad Industrial',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,49',
        ranking_demanda: '22',
        ranking_competitividad: '67',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Promedio',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 67 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda promedio, está en el lugar 22 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera técnico profesional con demanda promedio y competencia baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_3',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '128',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Industrial',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,78',
        ranking_demanda: '23',
        ranking_competitividad: '116',
        texto_nivel_competividad: 'Alta',
        texto_nivel_demanda: 'Promedio',
        descripcion_competencia:
          'Esta carrera tiene una competencia alta, está en el lugar 116 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda promedio, está en el lugar 23 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda promedio y competencia alta. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_3',
        color_tramo_competitividad: 'ctc_4',
      },
    ],
  },
  {
    id_carrera: '249',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Mecánica Industrial',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,51',
        ranking_demanda: '24',
        ranking_competitividad: '70',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Promedio',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 70 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda promedio, está en el lugar 24 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda promedio y competencia baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_3',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '38',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico Universitario en Informática',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,72',
        ranking_demanda: '25',
        ranking_competitividad: '87',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Promedio',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 87 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda promedio, está en el lugar 25 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda promedio y competencia baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_3',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '548',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico en Mantenimiento Mecánico',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,57',
        ranking_demanda: '26',
        ranking_competitividad: '74',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Promedio',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 74 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda promedio, está en el lugar 26 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera técnico profesional con demanda promedio y competencia baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_3',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '207',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico en Logística',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,92',
        ranking_demanda: '27',
        ranking_competitividad: '98',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Promedio',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 98 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda promedio, está en el lugar 27 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera técnico profesional con demanda promedio y competencia promedio. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_3',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '54',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Psicología',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,27',
        ranking_demanda: '28',
        ranking_competitividad: '109',
        texto_nivel_competividad: 'Alta',
        texto_nivel_demanda: 'Promedio',
        descripcion_competencia:
          'Esta carrera tiene una competencia alta, está en el lugar 109 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda promedio, está en el lugar 28 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda promedio y competencia alta. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_3',
        color_tramo_competitividad: 'ctc_4',
      },
    ],
  },
  {
    id_carrera: '58',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Química y Farmacia',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,16',
        ranking_demanda: '29',
        ranking_competitividad: '16',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Promedio',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 16 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda promedio, está en el lugar 29 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera profesional con demanda promedio y competencia muy baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_3',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '312',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Civil Mecánica',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,03',
        ranking_demanda: '30',
        ranking_competitividad: '106',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Promedio',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 106 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda promedio, está en el lugar 30 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda promedio y competencia promedio. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_3',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '123',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Construcción',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,91',
        ranking_demanda: '31',
        ranking_competitividad: '94',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Promedio',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 94 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda promedio, está en el lugar 31 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda promedio y competencia promedio. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_3',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '478',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Contador Público y Auditor',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,39',
        ranking_demanda: '32',
        ranking_competitividad: '108',
        texto_nivel_competividad: 'Alta',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia alta, está en el lugar 108 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 32 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda baja y competencia alta. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_4',
      },
    ],
  },
  {
    id_carrera: '315',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Administración de Hotelera',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,53',
        ranking_demanda: '33',
        ranking_competitividad: '58',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 58 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 33 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera técnico profesional con demanda baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '143',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Telecomunicaciones',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,48',
        ranking_demanda: '34',
        ranking_competitividad: '53',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 53 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 34 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera profesional con demanda baja y competencia muy baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '261',
    detalle_carrera: [
      {
        texto_nombre_carrera:
          'Ingeniería de Ejecución en Mecánica Automotriz y Autotrónica',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,62',
        ranking_demanda: '35',
        ranking_competitividad: '66',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 66 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 35 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda baja y competencia baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '6',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico en programación y analisis de sistemas',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,51',
        ranking_demanda: '36',
        ranking_competitividad: '55',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 55 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 36 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera técnico profesional con demanda baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '182',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Civil Electrónica',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,79',
        ranking_demanda: '37',
        ranking_competitividad: '79',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 79 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 37 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda baja y competencia baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '148',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Mecánica',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1',
        ranking_demanda: '38',
        ranking_competitividad: '92',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 92 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 38 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda baja y competencia promedio. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '118',
    detalle_carrera: [
      {
        texto_nombre_carrera:
          'Técnico en electricidad y electronica industrial',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,53',
        ranking_demanda: '39',
        ranking_competitividad: '54',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 54 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 39 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera técnico profesional con demanda baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '358',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Maquinaría y Vehiculos pesados',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,36',
        ranking_demanda: '40',
        ranking_competitividad: '33',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 33 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 40 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera profesional con demanda baja y competencia muy baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '366',
    detalle_carrera: [
      {
        texto_nombre_carrera:
          'Técnico en Administración de Empresas Mención Recursos Humanos',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,1',
        ranking_demanda: '41',
        ranking_competitividad: '113',
        texto_nivel_competividad: 'Alta',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia alta, está en el lugar 113 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 41 de 121.',
        descripcion_saturacion:
          'Saturada, carrera técnico profesional con demanda baja y competencia alta. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_4',
      },
    ],
  },
  {
    id_carrera: '360',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico en Farmacia',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,16',
        ranking_demanda: '42',
        ranking_competitividad: '8',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 8 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 42 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera técnico profesional con demanda baja y competencia muy baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '333',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería civil electricista',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,93',
        ranking_demanda: '43',
        ranking_competitividad: '82',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 82 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 43 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda baja y competencia baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '263',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico Agrícola',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,16',
        ranking_demanda: '44',
        ranking_competitividad: '7',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 7 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 44 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera técnico profesional con demanda baja y competencia muy baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '126',
    detalle_carrera: [
      {
        texto_nombre_carrera:
          'Ingeniería en Telecomunicaciones, Conectividad y Redes',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,32',
        ranking_demanda: '45',
        ranking_competitividad: '25',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 25 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 45 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera profesional con demanda baja y competencia muy baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '32',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Enfermería',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,11',
        ranking_demanda: '46',
        ranking_competitividad: '85',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 85 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 46 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda baja y competencia baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '477',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico en Prevención de Riesgos',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,8',
        ranking_demanda: '47',
        ranking_competitividad: '61',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 61 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 47 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera técnico profesional con demanda baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '536',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Ejecución Mecánica',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,25',
        ranking_demanda: '48',
        ranking_competitividad: '89',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 89 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 48 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda baja y competencia baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '63',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Trabajo Social',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,02',
        ranking_demanda: '49',
        ranking_competitividad: '75',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 75 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 49 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda baja y competencia baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '96',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería de Ejecución en Administración',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,57',
        ranking_demanda: '50',
        ranking_competitividad: '112',
        texto_nivel_competividad: 'Alta',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia alta, está en el lugar 112 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 50 de 121.',
        descripcion_saturacion:
          'Saturada, carrera profesional con demanda baja y competencia alta. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_4',
      },
    ],
  },
  {
    id_carrera: '427',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico Financiero',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,21',
        ranking_demanda: '51',
        ranking_competitividad: '83',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 83 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 51 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera técnico profesional con demanda baja y competencia baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '31',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Educación Parvularia',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,5',
        ranking_demanda: '52',
        ranking_competitividad: '32',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 32 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 52 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '109',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Ambiental',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,61',
        ranking_demanda: '53',
        ranking_competitividad: '38',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 38 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 53 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '446',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Ejecución Administración de Empresas',
        texto_nombre_tipo_carrera: 'Sin Definir',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,7',
        ranking_demanda: '54',
        ranking_competitividad: '111',
        texto_nivel_competividad: 'Alta',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia alta, está en el lugar 111 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 54 de 121.',
        descripcion_saturacion:
          'Saturada, carrera sin definir con demanda baja y competencia alta. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_4',
      },
    ],
  },
  {
    id_carrera: '545',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Civil Química',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,19',
        ranking_demanda: '55',
        ranking_competitividad: '77',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 77 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 55 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda baja y competencia baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '124',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Minas',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,09',
        ranking_demanda: '56',
        ranking_competitividad: '73',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 73 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 56 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda baja y competencia baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '190',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Electrónica',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,74',
        ranking_demanda: '57',
        ranking_competitividad: '48',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 48 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda baja, está en el lugar 57 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_4',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '552',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Recursos Humanos',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '1,9',
        ranking_demanda: '58',
        ranking_competitividad: '99',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 99 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 58 de 121.',
        descripcion_saturacion:
          'Saturada, carrera profesional con demanda muy baja y competencia promedio. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '157',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Conectividad y Redes',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,74',
        ranking_demanda: '59',
        ranking_competitividad: '47',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 47 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 59 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda muy baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '357',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería de Ejecución Industrial',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,09',
        ranking_demanda: '60',
        ranking_competitividad: '105',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 105 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 60 de 121.',
        descripcion_saturacion:
          'Saturada, carrera profesional con demanda muy baja y competencia promedio. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '340',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Comercio Internacional',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,06',
        ranking_demanda: '61',
        ranking_competitividad: '63',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 63 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 61 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '125',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Obras Civiles',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,39',
        ranking_demanda: '62',
        ranking_competitividad: '80',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 80 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 62 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '253',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Administración Financiera',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,89',
        ranking_demanda: '63',
        ranking_competitividad: '95',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 95 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 63 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia promedio. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '518',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Construcción Civil',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,43',
        ranking_demanda: '64',
        ranking_competitividad: '76',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 76 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 64 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '88',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Mecánica',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,05',
        ranking_demanda: '65',
        ranking_competitividad: '57',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 57 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 65 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera técnico profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '500',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Control de Gestión',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,03',
        ranking_demanda: '66',
        ranking_competitividad: '96',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 96 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 66 de 121.',
        descripcion_saturacion:
          'Saturada, carrera profesional con demanda muy baja y competencia promedio. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '52',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Periodismo',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,62',
        ranking_demanda: '66',
        ranking_competitividad: '84',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 84 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 66 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '27',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Diseño Gráfico',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,85',
        ranking_demanda: '68',
        ranking_competitividad: '44',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 44 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 68 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda muy baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '18',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Comercio Exterior',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '1,92',
        ranking_demanda: '69',
        ranking_competitividad: '91',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 91 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 69 de 121.',
        descripcion_saturacion:
          'Saturada, carrera técnico profesional con demanda muy baja y competencia promedio. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '486',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Agrícola',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,51',
        ranking_demanda: '70',
        ranking_competitividad: '23',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 23 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 70 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda muy baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '335',
    detalle_carrera: [
      {
        texto_nombre_carrera:
          'Ingeniería en Automatización y Control Industrial',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,94',
        ranking_demanda: '71',
        ranking_competitividad: '42',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 42 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 71 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda muy baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '396',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Control de Gestión',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,6',
        ranking_demanda: '72',
        ranking_competitividad: '101',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 101 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 72 de 121.',
        descripcion_saturacion:
          'Saturada, carrera profesional con demanda muy baja y competencia promedio. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '4',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Administración Pública',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,58',
        ranking_demanda: '73',
        ranking_competitividad: '97',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 97 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 73 de 121.',
        descripcion_saturacion:
          'Saturada, carrera profesional con demanda muy baja y competencia promedio. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '288',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Información y Control de Gestión',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,86',
        ranking_demanda: '74',
        ranking_competitividad: '78',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 78 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 74 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '116',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Pedagogía en Educación Básica',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,69',
        ranking_demanda: '75',
        ranking_competitividad: '27',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 27 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 75 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda muy baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '199',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico en Construcción',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '0,95',
        ranking_demanda: '76',
        ranking_competitividad: '36',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 36 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 76 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera técnico profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '307',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Publicidad',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,34',
        ranking_demanda: '77',
        ranking_competitividad: '86',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 86 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 77 de 121.',
        descripcion_saturacion:
          'Saturada, carrera técnico profesional con demanda muy baja y competencia baja. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '355',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Civil Matemática',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,74',
        ranking_demanda: '78',
        ranking_competitividad: '28',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 28 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 78 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda muy baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '57',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico en Análisis Químico',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,72',
        ranking_demanda: '79',
        ranking_competitividad: '26',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 26 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 79 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda muy baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '135',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico en Marketing',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'MUY_SATURADA',
        texto_escala_saturacion: 'Muy saturada',
        indice_saturacion: '2,75',
        ranking_demanda: '80',
        ranking_competitividad: '93',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 93 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 80 de 121.',
        descripcion_saturacion:
          'Muy saturada, carrera técnico profesional con demanda muy baja y competencia promedio. Si bien no podemos decirte que no estudies una carrera de este segmento, creemos que es importante que evalúes la relación que se da en estos casos entre competencia y campo laboral. Estás ante una saturación muy alta, lo que se traduce en que podrías enfrentar dificultades a la hora de incorporarte al mercado laboral. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_5',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '70',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Secretariado Ejecutivo',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'MUY_SATURADA',
        texto_escala_saturacion: 'Muy saturada',
        indice_saturacion: '3,37',
        ranking_demanda: '81',
        ranking_competitividad: '102',
        texto_nivel_competividad: 'Promedio',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia promedio, está en el lugar 102 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 81 de 121.',
        descripcion_saturacion:
          'Muy saturada, carrera técnico profesional con demanda muy baja y competencia promedio. Si bien no podemos decirte que no estudies una carrera de este segmento, creemos que es importante que evalúes la relación que se da en estos casos entre competencia y campo laboral. Estás ante una saturación muy alta, lo que se traduce en que podrías enfrentar dificultades a la hora de incorporarte al mercado laboral. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_5',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_3',
      },
    ],
  },
  {
    id_carrera: '516',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Ejecución Electrónica',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,36',
        ranking_demanda: '82',
        ranking_competitividad: '50',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 50 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 82 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '5',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Agronomía',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,08',
        ranking_demanda: '83',
        ranking_competitividad: '37',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 37 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 83 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '526',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Civil Telemática',
        texto_nombre_tipo_carrera: 'Sin Definir',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,28',
        ranking_demanda: '84',
        ranking_competitividad: '4',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 4 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 84 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera sin definir con demanda muy baja y competencia muy baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '413',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Ejecución en Gestión Industrial',
        texto_nombre_tipo_carrera: 'Sin Definir',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '1,9',
        ranking_demanda: '85',
        ranking_competitividad: '65',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 65 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 85 de 121.',
        descripcion_saturacion:
          'Saturada, carrera sin definir con demanda muy baja y competencia baja. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '156',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Manteniemiento de Maquinaria Pesada',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,51',
        ranking_demanda: '86',
        ranking_competitividad: '12',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 12 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 86 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera técnico profesional con demanda muy baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '260',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Marketing',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,13',
        ranking_demanda: '87',
        ranking_competitividad: '69',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 69 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 87 de 121.',
        descripcion_saturacion:
          'Saturada, carrera profesional con demanda muy baja y competencia baja. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '271',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico en Diseño Gráfico',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,58',
        ranking_demanda: '88',
        ranking_competitividad: '13',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 13 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 88 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera técnico profesional con demanda muy baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '61',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Sociología',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,24',
        ranking_demanda: '89',
        ranking_competitividad: '68',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 68 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 89 de 121.',
        descripcion_saturacion:
          'Saturada, carrera profesional con demanda muy baja y competencia baja. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '461',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico en Trabajo Social',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,63',
        ranking_demanda: '90',
        ranking_competitividad: '49',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 49 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 90 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '8',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Arquitectura',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,51',
        ranking_demanda: '91',
        ranking_competitividad: '43',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 43 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 91 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '97',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Alimentos',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,71',
        ranking_demanda: '92',
        ranking_competitividad: '51',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 51 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 92 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '46',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Medicina',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,49',
        ranking_demanda: '93',
        ranking_competitividad: '6',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 6 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 93 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda muy baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '130',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería Civil Metalúrgica',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,58',
        ranking_demanda: '94',
        ranking_competitividad: '45',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 45 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 94 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '554',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería civil ambiental',
        texto_nombre_tipo_carrera: 'Sin Definir',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,14',
        ranking_demanda: '95',
        ranking_competitividad: '60',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 60 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 95 de 121.',
        descripcion_saturacion:
          'Saturada, carrera sin definir con demanda muy baja y competencia muy baja. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '369',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico en Mantenimiento Industrial',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,19',
        ranking_demanda: '96',
        ranking_competitividad: '30',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 30 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 96 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera técnico profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '25',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Derecho',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,7',
        ranking_demanda: '97',
        ranking_competitividad: '41',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 41 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 97 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '276',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Administración Industrial',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,12',
        ranking_demanda: '98',
        ranking_competitividad: '56',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 56 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 98 de 121.',
        descripcion_saturacion:
          'Saturada, carrera profesional con demanda muy baja y competencia muy baja. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '60',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Relaciones Públicas',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'MUY_SATURADA',
        texto_escala_saturacion: 'Muy saturada',
        indice_saturacion: '2,97',
        ranking_demanda: '99',
        ranking_competitividad: '72',
        texto_nivel_competividad: 'Baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia baja, está en el lugar 72 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 99 de 121.',
        descripcion_saturacion:
          'Muy saturada, carrera profesional con demanda muy baja y competencia baja. Si bien no podemos decirte que no estudies una carrera de este segmento, creemos que es importante que evalúes la relación que se da en estos casos entre competencia y campo laboral. Estás ante una saturación muy alta, lo que se traduce en que podrías enfrentar dificultades a la hora de incorporarte al mercado laboral. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_5',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_2',
      },
    ],
  },
  {
    id_carrera: '451',
    detalle_carrera: [
      {
        texto_nombre_carrera:
          'Técnico de Nivel Superior en Computación e Informática',
        texto_nombre_tipo_carrera: 'Sin Definir',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,49',
        ranking_demanda: '100',
        ranking_competitividad: '34',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 34 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 100 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera sin definir con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '28',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Diseño Industrial',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,23',
        ranking_demanda: '101',
        ranking_competitividad: '29',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 29 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 101 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '381',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Tecnologías de la Información y Comunicación',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,07',
        ranking_demanda: '102',
        ranking_competitividad: '20',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 20 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 102 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '173',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Diseño',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,07',
        ranking_demanda: '103',
        ranking_competitividad: '39',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 39 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 103 de 121.',
        descripcion_saturacion:
          'Saturada, carrera profesional con demanda muy baja y competencia muy baja. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '53',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico en Programación Computacional',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,73',
        ranking_demanda: '104',
        ranking_competitividad: '10',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 10 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 104 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera profesional con demanda muy baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '121',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Logística y Transporte',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,72',
        ranking_demanda: '105',
        ranking_competitividad: '35',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 35 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 105 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '275',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Gestión de Operaciones Logísticas',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,29',
        ranking_demanda: '106',
        ranking_competitividad: '46',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 46 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 106 de 121.',
        descripcion_saturacion:
          'Saturada, carrera profesional con demanda muy baja y competencia muy baja. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '301',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Pedagogía en Lenguaje y Comunicación',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,33',
        ranking_demanda: '107',
        ranking_competitividad: '24',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 24 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 107 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '535',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Química',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,44',
        ranking_demanda: '108',
        ranking_competitividad: '21',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 21 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 108 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '283',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Química Industrial',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '0,94',
        ranking_demanda: '109',
        ranking_competitividad: '9',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 9 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 109 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '218',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico en Refrigeración',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'RECOMENDADA',
        texto_escala_saturacion: 'Muy baja saturación',
        indice_saturacion: '0,25',
        ranking_demanda: '110',
        ranking_competitividad: '1',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 1 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 110 de 121.',
        descripcion_saturacion:
          'Muy baja saturación, carrera técnico profesional con demanda muy baja y competencia muy baja. En este segmento encontrarás alternativas positivas donde desarrollarte, ya que la relación entre demanda y oferta indica que hay espacio para nuevos profesionales. Debes estar atento a los cambios en el mercado laboral y evaluar si otros indicadores, como la inverasión o la duración de la carrera, confirman o desmienten que está es una opción que se adapta a tus posibilidades. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_1',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '47',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Medicina Veterinaria',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,2',
        ranking_demanda: '111',
        ranking_competitividad: '15',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 15 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 111 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '226',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Topografía',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,77',
        ranking_demanda: '112',
        ranking_competitividad: '5',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 5 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 112 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera técnico profesional con demanda muy baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '71',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Secretariado Ejecutivo Bilingue',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'MUY_SATURADA',
        texto_escala_saturacion: 'Muy saturada',
        indice_saturacion: '3,48',
        ranking_demanda: '113',
        ranking_competitividad: '52',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 52 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 113 de 121.',
        descripcion_saturacion:
          'Muy saturada, carrera técnico profesional con demanda muy baja y competencia muy baja. Si bien no podemos decirte que no estudies una carrera de este segmento, creemos que es importante que evalúes la relación que se da en estos casos entre competencia y campo laboral. Estás ante una saturación muy alta, lo que se traduce en que podrías enfrentar dificultades a la hora de incorporarte al mercado laboral. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_5',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '42',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Kinesiología',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,5',
        ranking_demanda: '114',
        ranking_competitividad: '31',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 31 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 114 de 121.',
        descripcion_saturacion:
          'Saturada, carrera profesional con demanda muy baja y competencia muy baja. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '185',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Asistente Ejecutivo',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'MUY_SATURADA',
        texto_escala_saturacion: 'Muy saturada',
        indice_saturacion: '3,38',
        ranking_demanda: '115',
        ranking_competitividad: '40',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 40 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 115 de 121.',
        descripcion_saturacion:
          'Muy saturada, carrera técnico profesional con demanda muy baja y competencia muy baja. Si bien no podemos decirte que no estudies una carrera de este segmento, creemos que es importante que evalúes la relación que se da en estos casos entre competencia y campo laboral. Estás ante una saturación muy alta, lo que se traduce en que podrías enfrentar dificultades a la hora de incorporarte al mercado laboral. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_5',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '49',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Nutrición y Dietética',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,15',
        ranking_demanda: '116',
        ranking_competitividad: '22',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 22 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 116 de 121.',
        descripcion_saturacion:
          'Saturada, carrera profesional con demanda muy baja y competencia muy baja. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '102',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Estadística',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '1,93',
        ranking_demanda: '117',
        ranking_competitividad: '18',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 18 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 117 de 121.',
        descripcion_saturacion:
          'Saturada, carrera profesional con demanda muy baja y competencia muy baja. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '89',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Ingeniería en Acuicultura',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'INDIFERENTE',
        texto_escala_saturacion: 'Promedio de mercado',
        indice_saturacion: '1,13',
        ranking_demanda: '118',
        ranking_competitividad: '3',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 3 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 118 de 121.',
        descripcion_saturacion:
          'Promedio de mercado, carrera profesional con demanda muy baja y competencia muy baja. En este segmento hallarás carreras que ya han probado su atractivo y por ello siguen en demanda a pesar de que su oferta ya esté cubierta. Enfócate bien para tomar una buena decisión ya que a partir de acá estamos ingresando a una zona de posible riesgo. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_3',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '20',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Comunicación Audiovisual  y Multimedia',
        texto_nombre_tipo_carrera: 'Profesional',
        escala_saturacion: 'SATURADA',
        texto_escala_saturacion: 'Saturada',
        indice_saturacion: '2,51',
        ranking_demanda: '119',
        ranking_competitividad: '17',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 17 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 119 de 121.',
        descripcion_saturacion:
          'Saturada, carrera profesional con demanda muy baja y competencia muy baja. Ten cuidado si la carrera que te gusta está en este segmento, ya que es probable que no sea una buena opción. Evalúa otras opciones antes de tomar una decisión y considera que hay otros factores que debes considerar, como la inversión y duración de la carrera. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_4',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '202',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Edificación',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'POCO_SATURADA',
        texto_escala_saturacion: 'Baja saturación',
        indice_saturacion: '0,74',
        ranking_demanda: '120',
        ranking_competitividad: '2',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 2 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 120 de 121.',
        descripcion_saturacion:
          'Baja saturación, carrera técnico profesional con demanda muy baja y competencia muy baja. Estas carreras aún son atractivas ya que las variaciones entre oferta y demanda tienden de compensarse y aún pueden ingresar nuevos trabajadores aunque vacantes o competencia tengan valores bajos. En este segmento te recomendamos comparar y ver alternativas, ya que es un segmento que cambia con rapidez debido a factores externos. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_2',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
  {
    id_carrera: '245',
    detalle_carrera: [
      {
        texto_nombre_carrera: 'Técnico Jurídico',
        texto_nombre_tipo_carrera: 'Técnico Profesional',
        escala_saturacion: 'MUY_SATURADA',
        texto_escala_saturacion: 'Muy saturada',
        indice_saturacion: '3,17',
        ranking_demanda: '121',
        ranking_competitividad: '19',
        texto_nivel_competividad: 'Muy baja',
        texto_nivel_demanda: 'Muy baja',
        descripcion_competencia:
          'Esta carrera tiene una competencia muy baja, está en el lugar 19 de 121.',
        descripcion_demanda:
          'Esta carrera tiene una demanda muy baja, está en el lugar 121 de 121.',
        descripcion_saturacion:
          'Muy saturada, carrera técnico profesional con demanda muy baja y competencia muy baja. Si bien no podemos decirte que no estudies una carrera de este segmento, creemos que es importante que evalúes la relación que se da en estos casos entre competencia y campo laboral. Estás ante una saturación muy alta, lo que se traduce en que podrías enfrentar dificultades a la hora de incorporarte al mercado laboral. La escala de saturación va de 0,14 a 3,32, a mayor saturación mayor el riesgo. En ambos índices, demanda y competencia, una carrera en el lugar N°1 es la más atractiva.',
        color_tramo_saturacion: 'cts_5',
        color_tramo_demanda: 'ctd_5',
        color_tramo_competitividad: 'ctc_1',
      },
    ],
  },
]
