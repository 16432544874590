export var carreras = [
  { id: '40',nombre:'Ingeniería Comercial'},
  { id: '354',nombre:'Ingeniería Civil Industrial'},
  { id: '39',nombre:'Ingeniería Civil'},
  { id: '439',nombre:'Mecánica Automotriz'},
  { id: '2',nombre:'Administración de Empresas'},
  { id: '23',nombre:'Contador Auditor'},
  { id: '473',nombre:'Ingeniería en Administración de Empresas'},
  { id: '334',nombre:'Ingeniería Civil en Informática'},
  { id: '231',nombre:'Ingeniería en Prevención de Riesgos'},
  { id: '104',nombre:'Ingeniería en Computación e Informática'},
  { id: '238',nombre:'Técnico en Enfermería'},
  { id: '81',nombre:'Gastronomía y Cocina Internacional'},
  { id: '273',nombre:'Electromecánica'},
  { id: '450',nombre:'Técnico de Nivel Superior en Administración de Empresas'},
  { id: '192',nombre:'Ingeniería en Logística'},
  { id: '122',nombre:'Ingeniería Civil Eléctrica'},
  { id: '22',nombre:'Construcción Civil'},
  { id: '342',nombre:'Mantenimiento Industrial'},
  { id: '193',nombre:'Ingeniería en Mantenimiento Industrial'},
  { id: '99',nombre:'Contabilidad General'},
  { id: '511',nombre:'Administración Gastronómica'},
  { id: '204',nombre:'Técnico en Electricidad Industrial'},
  { id: '128',nombre:'Ingeniería Industrial'},
  { id: '249',nombre:'Mecánica Industrial'},
  { id: '38',nombre:'Técnico Universitario en Informática'},
  { id: '548',nombre:'Técnico en Mantenimiento Mecánico'},
  { id: '207',nombre:'Técnico en Logística'},
  { id: '54',nombre:'Psicología'},
  { id: '58',nombre:'Química y Farmacia'},
  { id: '312',nombre:'Ingeniería Civil Mecánica'},
  { id: '123',nombre:'Ingeniería en Construcción'},
  { id: '478',nombre:'Contador Público y Auditor'},
  { id: '315',nombre:'Administración de Hotelera'},
  { id: '143',nombre:'Telecomunicaciones'},
  { id: '261',nombre:'Ingeniería de Ejecución en Mecánica Automotriz y Autotrónica'},
  { id: '6',nombre:'Técnico en programación y analisis de sistemas'},
  { id: '182',nombre:'Ingeniería Civil Electrónica'},
  { id: '148',nombre:'Ingeniería Mecánica'},
  { id: '118',nombre:'Técnico en electricidad y electronica industrial'},
  { id: '358',nombre:'Ingeniería en Maquinaría y Vehiculos pesados'},
  { id: '366',nombre:'Técnico en Administración de Empresas Mención Recursos Humanos'},
  { id: '360',nombre:'Técnico en Farmacia'},
  { id: '333',nombre:'Ingeniería civil electricista'},
  { id: '263',nombre:'Técnico Agrícola'},
  { id: '126',nombre:'Ingeniería en Telecomunicaciones, Conectividad y Redes'},
  { id: '32',nombre:'Enfermería'},
  { id: '477',nombre:'Técnico en Prevención de Riesgos'},
  { id: '536',nombre:'Ingeniería en Ejecución Mecánica'},
  { id: '63',nombre:'Trabajo Social'},
  { id: '96',nombre:'Ingeniería de Ejecución en Administración'},
  { id: '427',nombre:'Técnico Financiero'},
  { id: '31',nombre:'Educación Parvularia'},
  { id: '109',nombre:'Ingeniería Ambiental'},
  { id: '446',nombre:'Ingeniería Ejecución Administración de Empresas'},
  { id: '545',nombre:'Ingeniería Civil Química'},
  { id: '124',nombre:'Ingeniería en Minas'},
  { id: '190',nombre:'Ingeniería Electrónica'},
  { id: '552',nombre:'Ingeniería en Recursos Humanos'},
  { id: '157',nombre:'Ingeniería en Conectividad y Redes'},
  { id: '357',nombre:'Ingeniería de Ejecución Industrial'},
  { id: '340',nombre:'Ingeniería en Comercio Internacional'},
  { id: '125',nombre:'Ingeniería en Obras Civiles'},
  { id: '253',nombre:'Administración Financiera'},
  { id: '518',nombre:'Ingeniería en Construcción Civil'},
  { id: '88',nombre:'Mecánica'},
  { id: '500',nombre:'Control de Gestión'},
  { id: '52',nombre:'Periodismo'},
  { id: '27',nombre:'Diseño Gráfico'},
  { id: '18',nombre:'Comercio Exterior'},
  { id: '486',nombre:'Ingeniería Agrícola'},
  { id: '335',nombre:'Ingeniería en Automatización y Control Industrial'},
  { id: '396',nombre:'Ingeniería en Control de Gestión'},
  { id: '4',nombre:'Administración Pública'},
  { id: '288',nombre:'Ingeniería en Información y Control de Gestión'},
  { id: '116',nombre:'Pedagogía en Educación Básica'},
  { id: '199',nombre:'Técnico en Construcción'},
  { id: '307',nombre:'Publicidad'},
  { id: '355',nombre:'Ingeniería Civil Matemática'},
  { id: '57',nombre:'Técnico en Análisis Químico'},
  { id: '135',nombre:'Técnico en Marketing'},
  { id: '70',nombre:'Secretariado Ejecutivo'},
  { id: '516',nombre:'Ingeniería en Ejecución Electrónica'},
  { id: '5',nombre:'Agronomía'},
  { id: '526',nombre:'Ingeniería Civil Telemática'},
  { id: '413',nombre:'Ingeniería Ejecución en Gestión Industrial'},
  { id: '156',nombre:'Manteniemiento de Maquinaria Pesada'},
  { id: '260',nombre:'Ingeniería en Marketing'},
  { id: '271',nombre:'Técnico en Diseño Gráfico'},
  { id: '61',nombre:'Sociología'},
  { id: '461',nombre:'Técnico en Trabajo Social'},
  { id: '8',nombre:'Arquitectura'},
  { id: '97',nombre:'Ingeniería en Alimentos'},
  { id: '46',nombre:'Medicina'},
  { id: '130',nombre:'Ingeniería Civil Metalúrgica'},
  { id: '554',nombre:'Ingeniería civil ambiental'},
  { id: '369',nombre:'Técnico en Mantenimiento Industrial'},
  { id: '25',nombre:'Derecho'},
  { id: '276',nombre:'Ingeniería en Administración Industrial'},
  { id: '60',nombre:'Relaciones Públicas'},
  { id: '451',nombre:'Técnico de Nivel Superior en Computación e Informática'},
  { id: '28',nombre:'Diseño Industrial'},
  { id: '381',nombre:'Tecnologías de la Información y Comunicación'},
  { id: '173',nombre:'Diseño'},
  { id: '53',nombre:'Técnico en Programación Computacional'},
  { id: '121',nombre:'Ingeniería en Logística y Transporte'},
  { id: '275',nombre:'Ingeniería en Gestión de Operaciones Logísticas'},
  { id: '301',nombre:'Pedagogía en Lenguaje y Comunicación'},
  { id: '535',nombre:'Química'},
  { id: '283',nombre:'Química Industrial'},
  { id: '218',nombre:'Técnico en Refrigeración'},
  { id: '47',nombre:'Medicina Veterinaria'},
  { id: '226',nombre:'Topografía'},
  { id: '71',nombre:'Secretariado Ejecutivo Bilingue'},
  { id: '42',nombre:'Kinesiología'},
  { id: '185',nombre:'Asistente Ejecutivo'},
  { id: '49',nombre:'Nutrición y Dietética'},
  { id: '102',nombre:'Estadística'},
  { id: '89',nombre:'Ingeniería en Acuicultura'},
  { id: '20',nombre:'Comunicación Audiovisual  y Multimedia'},
  { id: '202',nombre:'Edificación'},
  { id: '245',nombre:'Técnico Jurídico'},
  ]
  